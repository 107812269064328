/**
 * 接口域名的管理
 */
let base = {
  sdkAppId: 1600043319,

  secretKey: 'c38eefb921b08a15dcd6e39f0df28d882fc9b895beccbe9731efa848ad5fe832',
  IM_prefix: 'user', //IM用户id前缀（user+用户id）
  IM_systemId: 'C2Csystem',//系统消息会话ID
  chatImg: 'https://test-i.seestech.com:1123', //用于判断聊天是否为图片
  agreementUrl: 'https://ihospital.seestech.com:1123/iotss/webApp/html/', //协议地址-正式
  imgUrl: 'https://ihospital.seestech.com:1123/iotss/static',
	// 生产
  // baseUrl: 'https://ihospital.seestech.com',
	// wsUrl: 'wss://ihospital.seestech.com/websocket',
  // loginWsUrl: 'wss://ihospital.seestech.com/websocket', // 用于app扫码登陆
  // pageUrl: "https://www.ilovehealth.com.cn", // 此url用于用户微信扫码"登录"发布生产时需注意更换
	// 开发
  baseUrl: 'https://api.llovehealth.com',
	wsUrl: 'wss://api.llovehealth.com/websocket',
  loginWsUrl: 'wss://api.llovehealth.com/websocket', // 用于app扫码登陆
	pageUrl: "https://www.llovehealth.com",// 此url用于用户微信扫码"登录"发布生产时需注意更换

  // 本地 帅
  // baseUrl: 'http://192.168.10.25',
  // wsUrl: 'ws://192.168.10.25:8000/websocket?',
  // loginWsUrl: 'ws://192.168.10.25/websocket',
  // 本地 琪
  // baseUrl: "http://192.168.10.39",
  // wsUrl: 'ws://192.168.10.39:8000/websocket?',
  // pageUrl: "https://test-ilovehealth.seestech.com" // 此url用于用户微信扫码登录发布生产时需注意更换
};
export default base;
