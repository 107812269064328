import $store from '@/store'
import eventHub from '@/utils/eventHub'
import IM_action from '@/common/js/IM-action.js'
import {
  extractProperties
} from '@/utils/index.js'
//处理音视频通话邀请
//全局事件监听处理
export function message_received(event) {
  // console.log('全局监听::message_received:::', event);
  // eventHub.$emit('IM-message', event); //用于音视频界面监听消息
  // 筛选出当前会话的消息
  let messageList = event.data;
  if (Array.isArray(messageList)) {
    // 筛选出自定义消息类型
    const result = messageList.filter(item => item.type === window.TIM_TYPES.MSG_CUSTOM)
    result.forEach((item, index) => {
      answerAndSend_notice(item)
    })
  } else if(messageList.type === window.TIM_TYPES.MSG_CUSTOM) {
    answerAndSend_notice(messageList)
  }
  eventHub.$emit('received-message-notice'); //用于播放提示音
}
//设置已读
export function im_setMessageRead(conversationID) {
  try {
    let promise = TIMchat.setMessageRead({
      conversationID: conversationID
    })
    promise
      .then((imResponse) => {
        console.log(imResponse)
      })
      .catch((err) => {
        console.warn(err) // 获取其他用户资料失败的相关信息
      })
  } catch (e) {
    console.warn(e)
    //TODO handle the exception
  }
}
export function conversationListUpdated(){
  //获取消息列表
  TIMchat.getConversationList().then(listRes => {
    // 全量的会话列表，用该列表覆盖原有的会话列表
    const conversationList = listRes.data.conversationList
    parse_list(conversationList)//数据格式解析
    conversationList.forEach((message) => {
      if (message.lastMessage) {
        try {
          message.lastMessage.messageForShow = getLastMessage(message) //处理会话列表消息显示
        } catch (e) {}
      }
    })
    // 从云端同步会话列表是否完成
    const isSyncCompleted = listRes.data.isSyncCompleted
    console.log('全局监听::conversationList:::::::::::', conversationList)
    $store.commit('chat/set_allConversation', conversationList)
  }).catch(err => {

  })
}


//返回头像
export function getAvatar(current_conversation) {
  try {
    current_conversation = JSON.parse(JSON.stringify(current_conversation))
    switch (current_conversation.type) {
      case 'C2C':
        return current_conversation.userProfile.avatar
      case 'GROUP':
        return current_conversation.groupProfile.avatar
    }
  } catch (e) {
    console.error(e)
  }
}
//返回昵称
export function getShowName(current_conversation) {
  try {
    // current_conversation = JSON.parse(JSON.stringify(current_conversation))
    switch (current_conversation.type) {
      case 'C2C':
        // return current_conversation.userProfile.nick || current_conversation.userProfile.userID
        return current_conversation.userProfile.nick
      case 'GROUP':
        // return current_conversation.groupProfile.name || current_conversation.groupProfile.groupID
        return current_conversation.groupProfile.name
    }
  } catch (e) {
    console.error(e)
  }
}
//返回最后一条消息显示
export function getLastMessage(current_conversation) {
  try {
    if(current_conversation.lastMessage.type === "TIMCustomElem" && current_conversation.lastMessage.payload.data.actionType){
      // 信令消息
      switch (current_conversation.lastMessage.payload.data.actionType) {
        case 1:
          return '[邀请你通话]'
        case 2:
          return '[取消通话邀请]'
        case 3:
          return '[已接听]'
        case 4:
          return '[拒绝通话邀请]'
        case 5:
          return '[未接听]'
        default:
          return '[自定义消息]'
      }
    }else if(current_conversation.lastMessage.type === "TIMCustomElem" && current_conversation.lastMessage.payload.data.action) {
      // 自定义消息
      switch (current_conversation.lastMessage.payload.data.action) {
        case "patient_condition_description":
          return '[病情描述]'
        case 'prescription':
          return '[处方笺]'
        case 'req_address':
          return '[收货地址]'
        case 'user_send_drugs':
          return '[药品咨询]'
        case 'doctor_do_clinic':
          return current_conversation.lastMessage.payload.data.msg
        default:
          return '[自定义消息]'
      }
    }else{
      return current_conversation.lastMessage.messageForShow
    }
  } catch (e) {
    console.error(e)
  }
}

//返回发送to
export function getToAccount(current_conversation) {
  try {
    current_conversation = JSON.parse(JSON.stringify(current_conversation))
    switch (current_conversation.type) {
      case 'C2C':
        return current_conversation.conversationID.replace('C2C', '')
      case 'GROUP':
        return current_conversation.conversationID.replace('GROUP', '')
    }
  } catch (e) {
    console.error(e)
  }
}
// 收到`接诊消息`,通知<message-chat>获取最新问诊状态
export function answerAndSend_notice(messageData) {
  const item_data = parse_msg(messageData)
  if(item_data.type === 'TIMCustomElem' && item_data.payload.data.action === IM_action.doctor_do_clinic) {
    eventHub.$emit('doctor-do-clinic', item_data); //获取当前问诊状态
  }
}

// 消息解析处理
export function parse_msg(messageData) {
  try{
    if (Array.isArray(messageData)) {
      messageData.forEach((item, index) => {
        item = _parse(item)
      })
    } else {
      messageData = _parse(messageData)
    }
  }catch(e){
    //TODO handle the exception
  }
  return messageData

}

function _parse(item) {
  switch (item.type) {
    //如果是自定义消息类型。需要解析 payload.data 和 payload.data.data
    //即：普通自定义消息内容都放到payload.data中（JSON格式）；信令消息只能在payload.data.data中
    //所以一般有 payload.data.data 的，应该都是信令消息
    case 'TIMCustomElem':
      try {
        //解析data里面的data
        item.payload.data = JSON.parse(item.payload.data)
      } catch (e) {}
      try {
        //解析data里面的data
        item.payload.data.data = JSON.parse(item.payload.data.data)
      } catch (e) {}
      break
    default:
      //默认会在 cloudCustomData 携带JSON自定义消息
      try {
        item.cloudCustomData = JSON.parse(item.cloudCustomData)
      } catch (e) {}
      break
  }

  return item
}
//消息列表数据解析
export function parse_list(messageData){
  try{
    if (Array.isArray(messageData)) {
      messageData.forEach((item, index) => {
        item.lastMessage = _parse(item.lastMessage)
      })
    } else {
      messageData.lastMessage = _parse(messageData.lastMessage)
    }
  }catch(e){
    //TODO handle the exception
  }
  return messageData
}
