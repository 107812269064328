import axios from '@/api/http'
import base from '@/api/base'

const history = {

  // 请求处方笺记录
  gethistoryList (data) {
    return axios.post(`${base.baseUrl}/clinic/doctorClinicPrescription/pageMyDoctorClinicPrescriptionDTO`, data)
  },
  doctorClinicPrescriptionPage (data) {
    return axios.post(`${base.baseUrl}/clinic/doctorClinicPrescription/doctorClinicPrescriptionPage`,data)
  },
}

export default history
